import Image from 'next/image'
import type { FC } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { Token } from '~/types/tokens'

const TokenLogo: FC<{ token?: Token; classname?: string; size?: number }> = ({
	token,
	classname,
	size
}) => {
	if (!token || token?.logoURI === '' || !token?.logoURI)
		return <AiOutlineQuestionCircle size={size ?? 24} className={classname} />
	return (
		<Image
			className={classname}
			src={token.logoURI}
			width={size ?? 24}
			height={size ?? 24}
			loading="lazy"
			alt={`${token.symbol} logo`}
			style={{ maxHeight: `${size ?? 24}px` }}
		/>
	)
}

export default TokenLogo
