export type PairDataNormalized = {
	time: number
	token0Id: string
	token1Id: string
	reserve0: number
	reserve1: number
}[]

export type DerivedPairDataNormalized = {
	time: number
	token0Id: string
	token1Id: string
	token0DerivedBNB: number
	token1DerivedBNB: number
}[]

export type PairPricesNormalized = {
	time: Date
	value: number
}[]

export enum PairDataTimeWindowEnum {
	DAY,
	WEEK,
	MONTH,
	YEAR
}

export type fetchPairDataParams = {
	pairId: string
	timeWindow: PairDataTimeWindowEnum
}

export type LastPairHourIdResponse = {
	pairHourDatas: {
		id: string
	}[]
}

export type LastPairDayIdResponse = {
	pairDayDatas: {
		id: string
	}[]
}

export type PairHoursDatasResponse = {
	pairHourDatas: {
		id: string
		hourStartUnix: number
		reserve0: string
		reserve1: string
		reserveUSD: string
		pair: {
			token0: {
				id: string
			}
			token1: {
				id: string
			}
		}
	}[]
}

export type PairDayDatasResponse = {
	pairDayDatas: {
		id: string
		date: number
		reserve0: string
		reserve1: string
		reserveUSD: string
		pairAddress: {
			token0: {
				id: string
			}
			token1: {
				id: string
			}
		}
	}[]
}

export interface Block {
	number: number
	timestamp: string
}
