import axios from 'axios'
import { ReactNode, useEffect, useState } from 'react'
import { setEnvConfig } from '~/utils/envConfig'

export const EnvConfigProvider = ({ children }: { children: ReactNode }) => {
	const [loadingState, setLoadingState] = useState<
		'not-loaded' | 'error' | 'loaded'
	>('not-loaded')

	useEffect(() => {
		const loadConfig = async () => {
			try {
				const configResponse = await axios.get(`/api/config`)
				setLoadingState('loaded')
				setEnvConfig(configResponse.data.config)
			} catch (err) {
				console.error(err)
				setLoadingState('error')
			}
		}
		loadConfig()
	}, [])

	return (
		<>
			{loadingState === 'not-loaded' && (
				<AppLoadingInfo>
					<div className="text-xl text-slate-500"></div>
				</AppLoadingInfo>
			)}
			{loadingState === 'error' && (
				<AppLoadingInfo>
					<div className="text-xl text-slate-500">Error</div>
				</AppLoadingInfo>
			)}

			{loadingState === 'loaded' && children}
		</>
	)
}

function AppLoadingInfo({ children }: { children: ReactNode }) {
	return (
		<div className="from-50% from-themeDarkGreen to-themeTeal flex h-screen w-screen items-center justify-center bg-gradient-to-r">
			{children}
		</div>
	)
}
