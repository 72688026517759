import {
	ChangeEvent,
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState
} from 'react'
import { useDebounce } from 'usehooks-ts'
import { isNumeric } from '~/utils'
import clsx from 'clsx'
import { GoGear } from 'react-icons/go'
import useOutsideAlerter from '~/hooks/useOutsideAlerter'

interface SettingsConfig {
	slippage: number
	deadline: number
	isSecure: boolean
	showSettingModal: () => void
}

const SettingsContext = createContext<SettingsConfig>({
	slippage: 0.5,
	deadline: 20,
	isSecure: false,
	showSettingModal: () => {}
})

export const SettingsModalProvider = ({
	children
}: {
	children: ReactNode
}) => {
	const [slippage, setSlippage] = useState(0.5)
	const [deadline, setDeadline] = useState(20)
	const [inputSlippage, setInputSlippage] = useState<string>(
		slippage.toString()
	)
	const [isSecure, setIsSecure] = useState(false)
	const debounceInputSlippage = useDebounce(inputSlippage, 150)
	const [slippageValidationError, setSlippageValidationError] = useState('')
	const [modalOpened, setModalOpened] = useState(false)
	const modalRef = useRef(null)
	useOutsideAlerter(modalRef, () => {
		if (modalOpened) {
			setModalOpened(false)
		}
	})

	useEffect(() => {
		const validityError = checkValidityErrors(Number(debounceInputSlippage))
		if (!validityError) {
			handleSlippage(debounceInputSlippage)
		}
		setSlippageValidationError(validityError)
	}, [debounceInputSlippage])

	useLayoutEffect(() => {
		setSlippage(JSON.parse(localStorage.getItem('sw_slippage') ?? '0.5'))
		setInputSlippage(JSON.parse(localStorage.getItem('sw_slippage') ?? '0.5'))
		setDeadline(JSON.parse(localStorage.getItem('sw_deadline') ?? '20'))
		setIsSecure(!!JSON.parse(localStorage.getItem('sw_isSecure') ?? '0'))
	}, [])

	const handleSlippage = (n: string) => {
		setSlippage(+n)
		setInputSlippage(n)
		localStorage.setItem('sw_slippage', JSON.stringify(n))
	}

	const handleIsSecure = (value: boolean) => {
		setIsSecure(value)
		localStorage.setItem('sw_isSecure', JSON.stringify(+value))
	}

	const handleDeadline = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		const value = Math.max(1, Math.min(60, Number(e.target.valueAsNumber)))
		setDeadline(value)
		localStorage.setItem('sw_deadline', JSON.stringify(value))
	}

	const checkValidityErrors = (val: number) => {
		if (!isNumeric(val) || val < 0.1 || val > 49) {
			return `Please provide a positive numeric value between 0.1 and 49`
		} else if (!Number.isInteger(val)) {
			// if 0.03 => ['0', '03'];
			const valuesArr = (val + '').split('.')
			if (valuesArr.length > 1 && valuesArr[1].length > 1) {
				return `Please provide a value with max 1 number after the comma`
			}
		}
		return ''
	}

	const resetValuesIfInvalid = () => {
		if (slippageValidationError) {
			setInputSlippage(slippage.toString())
			setSlippageValidationError('')
		}
	}

	const closeModal = () => {
		setModalOpened(false)
		resetValuesIfInvalid()
	}

	return (
		<SettingsContext.Provider
			value={{
				slippage,
				deadline,
				isSecure,
				showSettingModal: () => setModalOpened(true)
			}}
		>
			{children}
			<div
				className={clsx(
					'modal-custom modal',
					modalOpened ? 'modal-open' : 'modal-close'
				)}
			>
				<div
					className="modal-box bg-secondary p-0"
					ref={modalRef}
					style={{ maxWidth: 550 }}
				>
					<div className="flex w-full flex-row items-center justify-between p-6 pb-0">
						<h3 className="text-md font-bold uppercase text-warning">
							Settings
						</h3>

						<div className="modal-action mt-0">
							<button
								onClick={closeModal}
								className="btn-ghost btn-xs btn-circle btn"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-4 w-4"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
					</div>
					<div className="flex max-h-[70vh] flex-col gap-4 overflow-y-auto p-6">
						<div className="flex flex-col gap-8 rounded-2xl bg-warning p-4">
							<h3 className="uppercase text-primary">Slippage tolerance</h3>
							<div className="flex w-full flex-row justify-between gap-2">
								<div className="flex w-fit gap-2">
									<button
										className={`${
											slippage === 0.1 ? 'btn-primary' : 'btn-success'
										} btn-sm btn rounded-full px-4 font-normal`}
										onClick={() => handleSlippage('0.1')}
									>
										0.1%
									</button>
									<button
										className={`${
											slippage === 0.5 ? 'btn-primary' : 'btn-success'
										} btn-sm btn rounded-full px-4 font-normal`}
										onClick={() => handleSlippage('0.5')}
									>
										0.5%
									</button>
									<button
										className={`${
											slippage === 1 ? 'btn-primary' : 'btn-success'
										} btn-sm btn rounded-full px-4 font-normal`}
										onClick={() => handleSlippage('1')}
										value={1}
									>
										1.0%
									</button>
								</div>
								<div className="relative flex flex-row items-center gap-2 justify-self-end overflow-hidden rounded-full border-[1px] border-primary">
									<input
										type="number"
										step="0.1"
										value={inputSlippage}
										onChange={(e) => {
											e.preventDefault()
											setInputSlippage(e.target.value)
										}}
										style={{ maxWidth: '5em' }}
										className="input input-sm rounded-full border border-primary bg-transparent text-left text-primary focus-within:bg-transparent focus-within:text-primary focus-within:outline-0"
									/>
									<div className="absolute right-0 flex h-full items-center rounded-r-2xl rounded-l-none bg-primary px-2 font-normal">
										<span>%</span>
									</div>
								</div>
							</div>
							{slippageValidationError && (
								<div className="text-error">{slippageValidationError}</div>
							)}
						</div>

						<div className="mt-4 flex flex-row items-center justify-between">
							<p className="font-normal uppercase">Transaction deadline</p>
							<div className="relative flex w-1/3 flex-row items-center gap-2 justify-self-end overflow-hidden rounded-full border border-warning">
								<input
									type="number"
									value={deadline}
									onChange={handleDeadline}
									className="input input-sm w-1/2 rounded-none rounded-l-full rounded-r-none bg-transparent text-left text-warning focus-within:bg-transparent focus-within:outline-0"
								/>
								<span className="absolute right-0 flex h-full w-1/2 items-center justify-center bg-warning px-2 font-normal text-primary">
									<p className="uppercase">mins</p>
								</span>
							</div>
						</div>
						<div className="mt-4 flex flex-row items-center justify-between">
							<p className="font-normal uppercase">Secure approvals</p>
							<input
								type="checkbox"
								className="toggle !border !border-secondary"
								checked={isSecure}
								onChange={() => handleIsSecure(!isSecure)}
							/>
						</div>
					</div>
				</div>
			</div>
		</SettingsContext.Provider>
	)
}

export const SettingsModalButton = () => {
	const { showSettingModal } = useSettingsModal()
	return (
		<button
			className="btn-ghost w-fit cursor-pointer pr-1"
			onClick={showSettingModal}
		>
			<GoGear />
		</button>
	)
}

const useSettingsModal = () => {
	const { slippage, deadline, isSecure, showSettingModal } =
		useContext(SettingsContext)
	return { slippage, deadline, isSecure, showSettingModal }
}

export default useSettingsModal
