export const TOKEN_HELPER_ABI = [
	{
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'spender',
				type: 'address'
			},
			{
				internalType: 'address[]',
				name: 'tokens',
				type: 'address[]'
			}
		],
		name: 'allowances',
		outputs: [
			{
				internalType: 'uint256[]',
				name: '',
				type: 'uint256[]'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address[]',
				name: 'accounts',
				type: 'address[]'
			},
			{
				internalType: 'address[]',
				name: 'tokens',
				type: 'address[]'
			}
		],
		name: 'balances',
		outputs: [
			{
				internalType: 'uint256[]',
				name: '',
				type: 'uint256[]'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address[]',
				name: 'accounts',
				type: 'address[]'
			},
			{
				internalType: 'address[]',
				name: 'tokens',
				type: 'address[]'
			}
		],
		name: 'balancesStruct',
		outputs: [
			{
				components: [
					{
						internalType: 'address',
						name: 'account',
						type: 'address'
					},
					{
						internalType: 'address',
						name: 'token',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'balance',
						type: 'uint256'
					}
				],
				internalType: 'struct TokenHelper.AccountBalance[]',
				name: '',
				type: 'tuple[]'
			}
		],
		stateMutability: 'view',
		type: 'function'
	}
] as const
