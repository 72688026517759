import React, { useMemo } from 'react'
import Link from 'next/link'
import SocialLinks from '~/components/SocialLinks'
import clsx from 'clsx'
import { useRouter } from 'next/router'

const pathForDarkBg = ['farms', 'pools', 'liquidity', 'swap']

const Footer = () => {
	const router = useRouter()

	const customBg = useMemo(() => {
		console.log(router.pathname)
		if (pathForDarkBg.some((path) => router.pathname.includes(path))) {
			return `bg-[#041e1d]`
		}
	}, [router.pathname])

	return (
		<footer
			className={clsx(
				'flex flex-col items-center gap-8 px-8 py-4 md:flex-row md:justify-between',
				customBg || 'bg-darkgreen'
			)}
		>
			<div className="flex-1">
				<Link href="/" className="text-lg font-semibold">
					KYOTOSWAP.IO
				</Link>
			</div>
			<div className="flex flex-1 justify-center">
				<SocialLinks />
			</div>
			<div className="flex flex-1 items-center justify-end gap-3 self-end md:self-auto">
				<div className="text-lg font-semibold">BUILT BY KYOTOLABS</div>
				<svg
					width="15"
					height="15"
					viewBox="0 0 15 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="7.5" cy="7.5" r="7.5" fill="white" />
				</svg>
			</div>
		</footer>
	)
}

export default Footer
