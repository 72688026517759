import { useAtom } from 'jotai'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { useAccount } from 'wagmi'
import { addressAtom } from '~/stores/user'
import Footer from './Footer'
import Navbar, { LINKS } from './Navbar'

const Layout = ({ children }: { children: ReactNode }) => {
	const [, setAddressAtom] = useAtom(addressAtom)
	const {} = useAccount({
		onConnect({ address }) {
			setAddressAtom(address as string)
		},
		onDisconnect() {
			setAddressAtom('')
		}
	})
	const router = useRouter()
	return (
		<div className="drawer drawer-end ">
			<input id="menu_drawer" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content relative flex flex-col">
				<Navbar />

				<main className="flex w-full flex-col items-center justify-center overflow-x-clip bg-white ">
					{children}
				</main>
				<Footer />
			</div>
			<div className="drawer-side">
				<label htmlFor="menu_drawer" className="drawer-overlay"></label>
				<ul className="menu menu-vertical w-48 bg-secondary p-4 md:w-80">
					<label htmlFor="menu_drawer" className="btn btn-square btn-ghost">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							className="inline-block h-6 w-6 stroke-current"
							fill="none"
						>
							<path
								d="M4.70711 3.29289C4.31658 2.90237 3.68342 2.90237 3.29289 3.29289C2.90237 3.68342 2.90237 4.31658 3.29289 4.70711L10.5858 12L3.29289 19.2929C2.90237 19.6834 2.90237 20.3166 3.29289 20.7071C3.68342 21.0976 4.31658 21.0976 4.70711 20.7071L12 13.4142L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L13.4142 12L20.7071 4.70711C21.0976 4.31658 21.0976 3.68342 20.7071 3.29289C20.3166 2.90237 19.6834 2.90237 19.2929 3.29289L12 10.5858L4.70711 3.29289Z"
								fill="#fff"
							/>
						</svg>
					</label>
					{LINKS.map((v) => (
						<li key={v.display}>
							{v.subroutes ? (
								<div className="collapse collapse-arrow grid p-0">
									<input
										type="checkbox"
										className="peer h-fit"
										style={{
											minHeight: '1rem'
										}}
									/>

									<div
										className="collapse-title px-4 py-3"
										style={{
											minHeight: '1rem'
										}}
									>
										{v.display}
									</div>
									<div className="collapse-content flex flex-col">
										{v.subroutes.map((v) => (
											<Link
												href={v.path}
												key={v.path}
												target={v.path.startsWith('http') ? '_blank' : '_self'}
												className="move-up py-3 no-underline hover:underline"
											>
												{v.display}
											</Link>
										))}
									</div>
								</div>
							) : (
								<Link
									href={v.path}
									target={v.path.startsWith('http') ? '_blank' : '_self'}
									className="move-up h-[60px] no-underline hover:underline"
								>
									{v.display}
								</Link>
							)}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export default Layout
