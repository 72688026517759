import { getAddress } from '@ethersproject/address'
import type { ClassValue } from 'clsx'
import clsx from 'clsx'
import format from 'date-fns/format'
import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils.js'
import { memoize, times } from 'lodash'
import { twMerge } from 'tailwind-merge'
import { timeWindowGapMapping } from '~/config/constants'
import { PairDataTimeWindowEnum } from '~/types/charts'
import { Farm } from '~/types/farms'
import { Pair } from '~/types/pair'
import { Token } from '~/types/tokens'
import { getEnvConfigValue } from './envConfig'

export function cm(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export function toFormattedValueN(number: number) {
	return `${number.toLocaleString('en-GB', {
		maximumFractionDigits: 2,
		minimumFractionDigits: 0
	})}`
}

export function toFormattedValue(v: string) {
	const number = Number(v)
	return `${number.toLocaleString('en-GB', {
		maximumFractionDigits: 2,
		minimumFractionDigits: 0
	})}`
}

export function toFormattedValueD(v: string, decimals: number) {
	const number = Number(v)
	return `${number.toLocaleString('en-GB', {
		maximumFractionDigits: decimals,
		minimumFractionDigits: decimals
	})}`
}

export function toFormattedValueDN(v: number, d: number) {
	return `${v.toLocaleString('en-GB', {
		maximumFractionDigits: d,
		minimumFractionDigits: 0
	})}`
}

export function toIntlCompact(
	v: number,
	notation: Intl.NumberFormatOptions['notation'] = 'compact'
) {
	const formatter = Intl.NumberFormat('en', { notation })
	return formatter.format(v)
}

const decimalCount = (num: any) => {
	// Convert to String
	const numStr = String(num)
	// String Contains Decimal
	if (numStr.includes('.')) {
		return numStr.split('.')[1].length
	}
	// String Does Not Contain Decimal
	return 0
}
export function isNumeric(n: any, decimals?: number) {
	return (
		!isNaN(parseFloat(n)) && isFinite(n) && decimalCount(n) <= (decimals ?? 18)
	)
}

export function trimmedAddress(address: string) {
	return `${address.substr(0, 6)}...${address.substr(-4)}`
}

export function applySlippage(n: BigNumber, slippage: number) {
	const slippageAmount = n
		.mul(slippage * Number(getEnvConfigValue('SLIPPAGE_MULTIPLIER')))
		.div(Number(getEnvConfigValue('DECIMAL_DENOMINATOR')))
	const res = n.sub(slippageAmount)
	return res
}

export function getPairFromTokens(tokenA: Token, tokenB: Token, pairs: Pair[]) {
	if (pairs.length === 0) return null
	const isTokenAKyoto = tokenA.id === 'KYOTO'
	const isTokenBKyoto = tokenB.id === 'KYOTO'
	const WKYOTO = getEnvConfigValue('WKYOTO')

	const pair = pairs.filter((v) => {
		const a1 = v.token0.id === tokenA.address.toLowerCase()
		const a2 = v.token1.id === tokenA.address.toLowerCase()
		const a3 = isTokenAKyoto && v.token0.id === WKYOTO.toLowerCase()
		const a4 = isTokenAKyoto && v.token1.id === WKYOTO.toLowerCase()
		const b1 = v.token0.id === tokenB.address.toLowerCase()
		const b2 = v.token1.id === tokenB.address.toLowerCase()
		const b3 = isTokenBKyoto && v.token0.id === WKYOTO.toLowerCase()
		const b4 = isTokenBKyoto && v.token1.id === WKYOTO.toLowerCase()
		return (a1 || a2 || a3 || a4) && (b1 || b2 || b3 || b4)
	})
	if (pair.length === 0) return null
	return pair[0]
}

export function getPairFromTokenAddresses(
	tokenA: string,
	tokenB: string,
	pairs: Pair[]
) {
	if (pairs.length === 0) return null
	const pair = pairs.filter((v) => {
		const a1 = v.token0.id === tokenA.toLowerCase()
		const a2 = v.token1.id === tokenA.toLowerCase()
		const b1 = v.token0.id === tokenB.toLowerCase()
		const b2 = v.token1.id === tokenB.toLowerCase()
		return (a1 || a2) && (b1 || b2)
	})
	if (pair.length === 0) return null
	return pair[0]
}

export function quote(
	amountA: BigNumber,
	reserveA: BigNumber,
	reserveB: BigNumber
) {
	return amountA.mul(reserveB).div(reserveA)
}

export function calculateLpPrice(farm: Farm) {
	const pair = farm.pairTokens
	if (!pair) return 0
	// Continue fair LP Token Formula
	const token0Value = Number(pair.token0.derivedUSD) * Number(pair.reserve0)
	const token1Value = Number(pair.token1.derivedUSD) * Number(pair.reserve1)
	const result = (token0Value + token1Value) / Number(pair.totalSupply)
	return result
}

/**
 * Get the APR value in %
 * @param rewardTokenPrice Token price in the same quote currency
 * @param tvl Value of all tokens in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
	rewardTokenPrice: number,
	tvl: number,
	tokenPerBlock: number
): number => {
	if (tvl === 0 || rewardTokenPrice === 0) return 0
	const totalRewardPricePerYear =
		rewardTokenPrice *
		tokenPerBlock *
		Number(getEnvConfigValue('BLOCKS_PER_YEAR'))
	const apr = totalRewardPricePerYear / tvl
	return apr * 100
}

export function timeStampToDate(
	timeStamp: number,
	toFormat: string = 'yyyy-MM-dd HH:mm'
) {
	if (timeStamp) {
		timeStamp = timeStamp * 1000
		const date = new Date(timeStamp)
		return format(date, toFormat)
	}
	return ''
}

export function truncateAddress(address: string, numberToShow: number = 5) {
	let first = address.substr(0, numberToShow)
	let last = address.substr(address.length - numberToShow)
	return first + '...' + last
}

export const getFormatedDate = (date: Date) => {
	const year = date.getFullYear().toString().substr(-2)
	const month = ('0' + (date.getMonth() + 1)).slice(-2)
	const day = ('0' + date.getDate()).slice(-2)
	const hours = ('0' + date.getHours()).slice(-2)
	const minutes = ('0' + date.getMinutes()).slice(-2)
	return `${day}/${month}/${year} ${hours}:${minutes}`
}

export const constants = {
	recent: 'RECENT',
	mostTree: 'MOST TREES'
}

type getPairSequentialIdParams = {
	id: string
	pairId: string
}
export const getPairSequentialId = ({
	id,
	pairId
}: getPairSequentialIdParams) => id.replace(`${pairId}-`, '')

type getPairHoursIdsByTimeWindowParams = {
	pairAddress: string
	pairLastId: string
	timeWindow: PairDataTimeWindowEnum
	idsCount: number
}

export const getIdsByTimeWindow = ({
	pairAddress,
	pairLastId,
	timeWindow,
	idsCount
}: getPairHoursIdsByTimeWindowParams) => {
	const pairLastIdAsNumber = Number(pairLastId)
	if (timeWindow === PairDataTimeWindowEnum.DAY) {
		return []
	}
	return times(
		idsCount,
		(value) =>
			`${pairAddress}-${
				pairLastIdAsNumber - value * timeWindowGapMapping[timeWindow]
			}`
	)
}
export const isAddress = memoize((value: any): string | false => {
	try {
		return getAddress(value)
	} catch {
		return false
	}
})

export function calculatePriceImpact(props: {
	pair: Pair
	inputToken: Token
	inputAmount: BigNumber
	outputToken: Token
	outputAmount: BigNumber
}) {
	const { pair, inputAmount, inputToken, outputToken } = props
	const pairInputToken =
		pair.token0.id.toLowerCase() === inputToken.address.toLowerCase() ? 0 : 1
	const pairOutputToken = pairInputToken === 0 ? 1 : 0
	const inputReserveString = pair[`reserve${pairInputToken}`] ?? '0'
	const inputReserve = parseUnits(
		Number(inputReserveString).toFixed(6),
		inputToken.decimals
	)

	const outputReserveString = pair[`reserve${pairOutputToken}`] ?? '0'
	const outputReserve = parseUnits(
		Number(outputReserveString).toFixed(6),
		outputToken.decimals
	)

	if (inputReserve.eq(0) || outputReserve.eq(0)) {
		return 0
	}
	//  calculate constant product x * y = k
	const constantProduct = inputReserve.mul(outputReserve)
	// calculate new reserves after trade
	const newInputReserve = inputReserve.add(inputAmount)
	const newOutputReserve = constantProduct.div(newInputReserve)
	// calculate price impact
	const outputAmount = outputReserve.sub(newOutputReserve)
	//this doesn't work for some reasong
	// const priceImpact = outputAmount.mul(100).div(outputReserve)
	// if we cast everything to numbers it does, i love ethers
	const priceImpact =
		(Number(formatUnits(outputAmount, outputToken.decimals)) * 100) /
		Number(formatUnits(outputReserve, outputToken.decimals))
	return priceImpact
}
