import { atom } from 'jotai'
import { Token } from '~/types/tokens'
import { getEnvConfigValue } from '~/utils/envConfig'
import { validateToken } from '~/validation/token'

export const addressAtom = atom('')

const isSSR = typeof window === 'undefined'

const localslippageAtom = atom(
	isSSR ? 0.5 : JSON.parse(localStorage.getItem('sw_slippage') ?? '0.5')
)
const slippageAtom = atom(
	(get) => get(localslippageAtom),
	(get, set, n: number) => {
		set(localslippageAtom, n)
		localStorage.setItem('sw_slippage', JSON.stringify(n))
	}
)
const localDeadlineAtom = atom(
	isSSR ? 20 : JSON.parse(localStorage.getItem('sw_deadline') ?? '20')
)
const deadlineAtom = atom(
	(get) => get(localDeadlineAtom),
	(get, set, n: number) => {
		set(localDeadlineAtom, n)
		localStorage.setItem('sw_deadline', JSON.stringify(n))
	}
)

const localIsSecureAtom = atom(
	isSSR ? 0 : JSON.parse(localStorage.getItem('sw_isSecure') ?? '0')
)
const isSecureAtom = atom(
	(get) => Boolean(get(localIsSecureAtom)),
	(get, set, v: boolean) => {
		set(localIsSecureAtom, v ? 1 : 0)
		localStorage.setItem('sw_isSecure', v ? '1' : '0')
	}
)

const validateLocalCustomTokens = (tokenList: Token[]) => {
	return tokenList.filter((i) => validateToken(i))
}

export const customTokensAtom = atom(
	(get) => get(localCustomTokensAtom()),
	(_get, set, token: Token) => {
		const currentItems = validateLocalCustomTokens(
			_get(localCustomTokensAtom())
		)
		if (!currentItems.find((i) => i.address === token.address)) {
			currentItems.push(token)
			set(localCustomTokensAtom(), currentItems)
			localStorage.setItem(
				getEnvConfigValue('LS_PROP_CUSTOM_TOKENS') as string,
				JSON.stringify(currentItems)
			)
		}
	}
)

const localCustomTokensAtom = () =>
	atom(
		isSSR
			? []
			: validateLocalCustomTokens(
					JSON.parse(
						localStorage.getItem(
							getEnvConfigValue('LS_PROP_CUSTOM_TOKENS') as string
						) ?? '[]'
					)
			  )
	)
