import { fetchBalance, readContract } from '@wagmi/core'
import { atomsWithQuery } from 'jotai-tanstack-query'
import { TOKEN_HELPER_ABI } from '~/abis/tokenHelper.abi'
import { Pair } from '~/types/pair'
import { Token } from '~/types/tokens'
import { getEnvConfigValue } from '~/utils/envConfig'
import { pairListAtom, tokenListAtom } from './tokens'
import { addressAtom } from './user'

const fetchBalances = async (tokens: Token[], address: string) => {
	const tokenAddresses = tokens.map((v) => v.address) as `0x${string}`[]
	const data = await readContract({
		address: getEnvConfigValue('TOKEN_HELPER_ADDRESS'),
		abi: TOKEN_HELPER_ABI,
		functionName: 'balances',
		args: [[address as `0x${string}`], tokenAddresses]
	})
	const balance = await fetchBalance({
		address: address as `0x${string}`
	})

	const balancesMap = new Map<string, string>()
	// add erc20 balance to map
	data.map((v, idx) => balancesMap.set(tokenAddresses[idx], v.toString()))
	balancesMap.set('KYOTO', balance.value.toString())
	return balancesMap
}

const fetchLpBalances = async (tokens: Pair[], address: string) => {
	const tokenAddresses = tokens.map((v) => v.id) as `0x${string}`[]
	const balancesMap = new Map<string, string>()
	try {
		const data = await readContract({
			address: getEnvConfigValue('TOKEN_HELPER_ADDRESS'),
			abi: TOKEN_HELPER_ABI,
			functionName: 'balances',
			args: [[address as `0x${string}`], tokenAddresses]
		})
		data.forEach((v, idx) => {
			if (v.eq('0')) return
			balancesMap.set(tokenAddresses[idx].toLowerCase(), v.toString())
		})
	} catch (err) {
		console.error('💦', err)
	}
	return balancesMap
}

export const [userBalancesAtom] = atomsWithQuery((get) => {
	return {
		queryKey: ['userBalances', get(tokenListAtom), get(addressAtom)],
		queryFn: ({ queryKey: [, tokens, address] }) =>
			fetchBalances(tokens as Token[], address as string),
		initialData: new Map<string, string>(),
		enabled: get(addressAtom) !== '',
		refetchInterval: 5_000
	}
})

export const [userLpBalancesAtom] = atomsWithQuery((get) => {
	return {
		queryKey: ['userBalances', get(pairListAtom), get(addressAtom)],
		queryFn: ({ queryKey: [, tokens, address] }) =>
			fetchLpBalances(tokens as Pair[], address as string),
		initialData: new Map<string, string>(),
		enabled: get(addressAtom) !== '',
		refetchInterval: 5_000
	}
})
