import { formatUnits } from 'ethers/lib/utils.js'

import { Token } from '~/types/tokens'
import { toFormattedValue } from '~/utils'
import { BsFillExclamationTriangleFill } from 'react-icons/bs'
import TokenLogo from '../Tokens/TokenLogo'
const TokenRow = ({
	token,
	handleToken
}: {
	token: Token
	handleToken: (token: Token) => void
}) => {
	return (
		<div className="flex w-full flex-col items-center">
			<div
				className="btn-ghost btn w-full items-center justify-between gap-2 rounded-none  bg-transparent  px-10 py-2  text-start normal-case transition-all delay-[0] ease-in  last:mb-2 hover:bg-secondary hover:text-white"
				onClick={() => {
					handleToken(token)
				}}
			>
				<div className="flex w-1/2 flex-row items-center gap-2">
					<TokenLogo token={token} />
					<div className="flex max-w-[80%] flex-col">
						<p className="text-sm font-bold">{token.symbol}</p>
						<p className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-xs font-[300]">
							{token.name}
						</p>
					</div>
				</div>
				<span className="flex flex-row">
					{!(token.derivedBNB || token.derivedUSD) && (
						<div
							className="flex-column flex-center tooltip tooltip-top tooltip-left tooltip-accent mr-5 flex"
							data-tip="There is no liquidity for this token."
						>
							<BsFillExclamationTriangleFill className="mr-1" />
							<span className=" text-xs font-[300]">No liquidity</span>
						</div>
					)}
					{toFormattedValue(
						formatUnits(token.userBalance ?? '0', token.decimals)
					)}
				</span>
			</div>
			<hr className="min-h-[1px] w-[90%] rounded border-0 bg-success" />
		</div>
	)
}

export default TokenRow
