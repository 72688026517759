import { ConnectButton } from '@rainbow-me/rainbowkit'
import Blockies from 'react-blockies'
import { useIsMounted, useMediaQuery } from 'usehooks-ts'

const ConnectWallet = () => {
	const matches = useMediaQuery('(max-width: 768px)')
	return (
		<ConnectButton.Custom>
			{({
				account,
				chain,
				openAccountModal,
				openChainModal,
				openConnectModal,
				mounted
			}) => {
				// Note: If your app doesn't use authentication, you
				// can remove all 'authenticationStatus' checks
				const ready = mounted
				const connected = ready && account && chain

				return (
					<div
						{...(!ready && {
							'aria-hidden': true,
							style: {
								opacity: 0,
								pointerEvents: 'none',
								userSelect: 'none'
							}
						})}
					>
						{(() => {
							if (!connected) {
								return (
									<button
										onClick={openConnectModal}
										type="button"
										className="b flex h-[40px] flex-row items-center gap-2 whitespace-nowrap rounded-full  bg-white px-2 py-3 text-sm uppercase text-primary sm:h-full md:px-4 md:py-3 lg:text-base"
									>
										{mounted && matches ? 'Connect' : 'Connect Wallet'}
									</button>
								)
							}

							if (chain.unsupported) {
								return (
									<button
										onClick={openChainModal}
										type="button"
										className="btn btn-md rounded-full bg-white font-bold uppercase md:btn-md"
									>
										Wrong network
									</button>
								)
							}

							return (
								<button
									onClick={openAccountModal}
									type="button"
									className="md:text-md btn btn-accent btn-md flex flex-row gap-2 rounded-full py-0 text-xs font-bold uppercase md:btn-md md:p-2"
								>
									<Blockies
										seed={account.address.toLowerCase()}
										size={9}
										scale={3}
										className="hidden rounded-full xl:block"
									/>

									<p>{account.displayName}</p>
								</button>
							)
						})()}
					</div>
				)
			}}
		</ConnectButton.Custom>
	)
}

export default ConnectWallet
