import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useAtom } from 'jotai'
import logo from 'media/logo.png'
import Image from 'next/image'
import Link from 'next/link'
import { ReactNode, useEffect, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { BsFillTreeFill, BsSafe2Fill } from 'react-icons/bs'
import { landingStatsAtom } from '~/stores/stats'
import { kswapAtom } from '~/stores/tokens'
import { toFormattedValueD, toFormattedValueDN, toIntlCompact } from '~/utils'
import ConnectWallet from '../ConnectWallet'

export interface ILink {
	display: string
	path: string
	icon?: ReactNode
	subroutes?: ILink[]
}

export const LINKS: ILink[] = [
	{
		display: 'TRADE',
		path: '/swap',
		subroutes: [
			{ display: 'SWAP', path: '/swap' },
			{ display: 'LIQUIDITY', path: '/liquidity' }
		]
	},
	{
		display: 'EARN',
		path: '/farms',
		subroutes: [
			{ display: 'FARMS', path: '/farms' },
			{ display: 'POOLS', path: '/pools' }
		]
	},
	{
		display: 'PLANT',
		path: '/',
		subroutes: [
			{ display: 'LEADERBOARD', path: '/impact' }
			// { display: 'VOTING', path: '/voting' }
		]
	},
	{
		display: 'INFO',
		path: '',
		subroutes: [
			{
				display: 'GITBOOK',
				path: 'https://kyotoswap-io.gitbook.io/kyotoswap/welcome/what-is-kyotoswap'
			}
		]
	}
]

const ParentLink = ({ v }: { v: ILink }) => {
	const WrapperTag = v.path ? Link : ('div' as keyof JSX.IntrinsicElements)
	return (
		<WrapperTag
			key={v.display}
			href={v.path}
			target={v.path.startsWith('http') ? '_blank' : '_self'}
			className={`move-up p-1 lg:px-4 lg:py-3 ${
				v.path ? 'no-underline hover:underline ' : 'cursor-default'
			}   `}
		>
			{v.display}
			{v.subroutes && <BiChevronDown />}
		</WrapperTag>
	)
}

const Navbar = () => {
	const [stats] = useAtom(landingStatsAtom)

	const { data: tvlData } = useQuery({
		queryKey: ['apiTvl'],
		queryFn: async () => {
			const { data } = await axios.get<{ tvl: number }>('/api/tvl')
			return data
		},
		refetchInterval: 10_000,
		initialData: { tvl: 0 }
	})

	const [kswap] = useAtom(kswapAtom)

	// create a timer that alternates every 5 seconds to determine which ticker to display
	const [alternateTickers, setAlternateTickers] = useState(false)
	useEffect(() => {
		const interval = setInterval(() => {
			setAlternateTickers((v) => !v)
		}, 5000)
		return () => clearInterval(interval)
	}, [])
	return (
		<header className="fixed left-0 right-0 top-0 z-[9999] flex w-full flex-row justify-between gap-2 bg-primary px-2	py-4 text-white md:px-8">
			<div className="flex flex-[33%] flex-row items-center gap-6">
				<Link href={'/'}>
					<Image
						src={logo.src}
						width={40}
						height={40}
						blurDataURL={logo.blurDataURL}
						alt="KyotoSwap logo"
						className="object-contain"
						priority
					/>
				</Link>
			</div>

			<div className="bottom-0 left-0 z-[1000] hidden w-full flex-[33%] flex-row items-end justify-center gap-2 bg-[#052C29] p-2 sm:relative sm:w-auto sm:items-center sm:bg-transparent sm:p-0 md:flex">
				<nav className="text-md hidden flex-row gap-4 lg:flex">
					<ul className="menu menu-horizontal p-0 text-xs lg:text-base">
						{LINKS.map((v) => (
							<li
								key={v.display}
								{...(v.subroutes ? { tabIndex: 0 } : {})}
								className="rounded-xl "
							>
								{v.subroutes ? (
									<>
										<ParentLink v={v} />
										<ul className="z-50 bg-primary p-4">
											{v.subroutes.map((v) => (
												<li key={v.display} className="">
													<Link
														href={v.path}
														key={v.path}
														target={
															v.path.startsWith('http') ? '_blank' : '_self'
														}
														className="move-up p-1 font-normal   no-underline hover:underline"
													>
														{v.display}
													</Link>
												</li>
											))}
										</ul>
									</>
								) : (
									<ParentLink v={v} />
								)}
							</li>
						))}
					</ul>
				</nav>
			</div>
			<div className="fixed bottom-0 left-0 z-10 flex w-full flex-[33%] flex-row items-end justify-center gap-2 bg-[#052C29] p-2 sm:relative sm:w-auto sm:items-center sm:bg-transparent sm:p-0 lg:justify-end">
				{alternateTickers ? (
					<div
						className="tooltip tooltip-bottom tooltip-accent h-full"
						data-tip="Trees planted"
					>
						{/* <Link
							href={ VERITREE_VIEW_URL}
							passHref
							className="flex flex-row items-center gap-2 rounded-full border-2 border-solid border-accent py-3 px-2 md:px-4"
							target="_blank"
							rel="noreferrer"
						>
							<BsFillTreeFill size={24} className="text-accent" />
							{treesOrdered && (
								<span className="text-xs lg:text-base">
									{toFormattedValueDN(treesOrdered, 0)}
								</span>
							)}
						</Link> */}
						<Link
							href={'/impact'}
							passHref
							className="flex h-full w-[90px] flex-row content-center items-center gap-0 rounded-full bg-white px-2 py-2 sm:gap-2 md:w-[120px] md:px-4 md:py-3"
						>
							<BsFillTreeFill size={24} className="text-black" />

							<span className="text-xs text-black lg:text-base">
								{toFormattedValueDN(stats?.treesPlanted ?? 0, 0)}
							</span>
						</Link>
					</div>
				) : (
					<div
						className="tooltip tooltip-bottom tooltip-accent h-full"
						data-tip="KyotoSwap TVL"
					>
						<Link
							href={'/farms'}
							passHref
							className="flex h-full w-[90px]  flex-row items-center gap-2 rounded-full bg-white px-2 py-2 md:w-[120px] md:px-4 md:py-3"
						>
							<BsSafe2Fill size={24} className="text-black" />

							<span className="text-xs text-black lg:text-base">
								${toIntlCompact(tvlData.tvl)}
							</span>
						</Link>
					</div>
				)}

				<Link
					href={'/swap'}
					passHref
					className="b  flex h-full flex-row items-center gap-2 rounded-full  bg-white px-2 py-2 md:px-4 md:py-3"
				>
					<Image
						src={'/kswapLogo.svg'}
						height={24}
						width={24}
						alt="kswap logo"
						className="max-w-none"
					/>
					<span className="text-xs text-black lg:text-base">
						${toFormattedValueD(kswap.derivedUSD, 2)}
					</span>
				</Link>
				<ConnectWallet />
			</div>
			<div className="flex-none lg:hidden">
				<label htmlFor="menu_drawer" className="btn btn-square btn-ghost">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						className="inline-block h-6 w-6 stroke-current"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M4 6h16M4 12h16M4 18h16"
						></path>
					</svg>
				</label>
			</div>
		</header>
	)
}

export default Navbar
