import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ethers } from 'ethers'
import { useState } from 'react'
import { erc20ABI, useContractReads } from 'wagmi'
import { Token } from '~/types/tokens'
import { getEnvConfigValue } from '~/utils/envConfig'

interface ICoingeckoResponse {
	name: string
	symbol: string
	detail_platforms: {
		[key: string]: { decimal_place: number; contract_address: string }
	}
	image?: {
		small: string
	}
	logoURI: string
}

const generateTokenObject = (data: any) => {
	return Object.assign(
		{
			address: '',
			id: '',
			chainId: getEnvConfigValue('CHAIN_ID'),
			decimals: null,
			logoURI: '',
			name: '',
			symbol: '',
			derivedBNB: '',
			derivedUSD: ''
		},
		data
	)
}

export default function useGetTokenInfo(
	tokenAddress: string,
	enabled: boolean
) {
	const isValidAddress = ethers.utils.isAddress(tokenAddress)
	const [token, setToken] = useState<Token | null>(null)
	const { data, isFetched, isFetching } = useQuery({
		enabled: isValidAddress && enabled,
		queryKey: [`customToken_${tokenAddress}`],
		queryFn: async () => {
			try {
				const { data } = await axios.get<ICoingeckoResponse>(
					`${getEnvConfigValue('COIN_GECKO_ENDPOINT')}${tokenAddress}`
				)
				if (data) {
					setToken(
						generateTokenObject({
							symbol: data.symbol,
							name: data.name,
							decimals:
								data.detail_platforms['binance-smart-chain'].decimal_place,
							address: tokenAddress,
							logoURI: data.image?.small
						})
					)
				} else {
					setToken(null)
				}
				return data
			} catch {
				setToken(null)
			}
		}
	})

	const { isFetching: isFetchingContracts } = useContractReads({
		enabled: !data && isFetched && isValidAddress && enabled,
		contracts: [
			{
				address: tokenAddress as `0x${string}`,
				abi: erc20ABI,
				functionName: 'symbol'
			},
			{
				address: tokenAddress as `0x${string}`,
				abi: erc20ABI,
				functionName: 'name'
			},
			{
				address: tokenAddress as `0x${string}`,
				abi: erc20ABI,
				functionName: 'decimals'
			}
		],
		onSuccess(data) {
			if (data && data[0] && data[1] && data[2]) {
				setToken(
					generateTokenObject({
						symbol: data[0] as unknown as string,
						name: data[1] as unknown as string,
						decimals: data[2] as unknown as number,
						address: tokenAddress
					})
				)
			} else {
				setToken(null)
			}
			return data
		}
	})
	return {
		customToken: enabled && isValidAddress ? token : null,
		isSearchingTokens: isFetching || isFetchingContracts
	}
}
