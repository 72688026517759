import { RainbowKitChain } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitChainContext'
import { Chain } from 'wagmi'
import { EXPLORER_CONFIG } from './constants'
import { getEnvConfigValue } from '~/utils/envConfig'

export const kyotoTestnet: () => Chain = () => ({
	id: Number(getEnvConfigValue('CHAIN_ID')),
	name: 'Kyoto Testnet',
	network: 'Kyoto Blockchain Testnet',
	rpcUrls: {
		default: {
			http: [getEnvConfigValue('KYOTO_TESTNET_URL')]
		},
		public: {
			http: [getEnvConfigValue('KYOTO_TESTNET_URL')]
		}
	},
	blockExplorers: {
		default: EXPLORER_CONFIG()
	},
	nativeCurrency: {
		decimals: 18,
		name: 'KYOTO',
		symbol: 'KYOTO'
	}
})

export const kyotoTestnetRainbow: () => RainbowKitChain = () => ({
	id: Number(getEnvConfigValue('CHAIN_ID')),
	iconUrl:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/info/logo.png'
})
