import '@rainbow-me/rainbowkit/styles.css'
import {
	Hydrate,
	QueryClient,
	QueryClientProvider
} from '@tanstack/react-query'
import { Provider } from 'jotai'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import React, { Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import AnalyticsWrapper from '~/components/AnalyticsWrapper'
import Layout from '~/components/Layout'
import Loading from '~/components/Loading'
import { SettingsModalProvider } from '~/providers/SettingsModalProvider'
import { TokenSelectionModalProvider } from '~/providers/TokenSelectionModalProvider'
import { EnvConfigProvider } from '~/envProvider/envConfigProvider'
import { Web3Provider } from '~/envProvider/web3Provider'
import '../styles/animations.css'
import '../styles/globals.css'

export { reportWebVitals } from 'next-axiom'

export default function App({ Component, pageProps }: AppProps) {
	const [queryClient] = React.useState(() => new QueryClient())

	return (
		<>
			<Script
				id="gtm-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','G-YBWLWTLHGN');`
				}}
			></Script>

			<QueryClientProvider client={queryClient}>
				<Provider>
					<EnvConfigProvider>
						<Web3Provider>
							<SettingsModalProvider>
								<TokenSelectionModalProvider>
									<Hydrate state={pageProps.dehydratedState}>
										<Suspense fallback={<Loading />}>
											<Layout>
												<Component {...pageProps} />
												<AnalyticsWrapper />
											</Layout>
										</Suspense>
										<Toaster position="top-right" reverseOrder={true} />
									</Hydrate>
								</TokenSelectionModalProvider>
							</SettingsModalProvider>
						</Web3Provider>
					</EnvConfigProvider>
				</Provider>
			</QueryClientProvider>
		</>
	)
}
