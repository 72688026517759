import { formatUnits } from 'ethers/lib/utils.js'
import { Token } from '~/types/tokens'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import Image from 'next/image'
import TokenLogo from '../Tokens/TokenLogo'

const TokenImport = ({
	token,
	onImport
}: {
	token: Token
	onImport: (token: Token) => void
}) => {
	return (
		<div className=" flex w-full items-center justify-between gap-2 rounded-none  bg-transparent py-2  px-10 text-start">
			<div className="flex flex-row items-center gap-2">
				<TokenLogo token={token} />
				<div className="flex flex-col">
					<p className="text-sm font-bold">{token.symbol}</p>
					<p className="text-xs font-[300]">{token.name}</p>
				</div>
			</div>
			<div>
				<button
					onClick={() => {
						onImport(token)
					}}
					className="btn rounded-full bg-accent font-bold uppercase"
				>
					import
				</button>
			</div>
		</div>
	)
}

export default TokenImport
