import { atomsWithQuery } from 'jotai-tanstack-query'
import { TotalStatsResponse } from '~/types/api'

export const [landingStatsAtom] = atomsWithQuery(() => {
	return {
		queryKey: ['landingStats'],
		queryFn: () =>
			fetch(`/api/stats`).then(
				(res) => res.json() as Promise<TotalStatsResponse>
			),
		initialData: null,
		refetchInterval: 60_000
	}
})
