import { GraphQLClient } from 'graphql-request'
import { PairDataTimeWindowEnum } from '~/types/charts'
import { ExplorerEnvConfigType, getEnvConfigValue } from '~/utils/envConfig'

export const EXPLORER_CONFIG = () =>
	({
		name: 'Kyoto Testnet Explorer',
		url: getEnvConfigValue('EXPLORER_URL')
	} as ExplorerEnvConfigType)

export const EXCHANGE_CLIENT = () =>
	new GraphQLClient(getEnvConfigValue('EXCHANGE_SUBGRAPH_ENDPOINT') as string)

// Specifies the amount of data points to query for specific time window
export const timeWindowIdsCountMapping: Record<PairDataTimeWindowEnum, number> =
	{
		[PairDataTimeWindowEnum.DAY]: 24,
		[PairDataTimeWindowEnum.WEEK]: 28,
		[PairDataTimeWindowEnum.MONTH]: 30,
		[PairDataTimeWindowEnum.YEAR]: 24
	}

// How many StreamingFast ids to skip when querying the data
export const timeWindowGapMapping: Record<PairDataTimeWindowEnum, number> = {
	[PairDataTimeWindowEnum.DAY]: 1,
	[PairDataTimeWindowEnum.WEEK]: 6, // Each datapoint 6 hours apart
	[PairDataTimeWindowEnum.MONTH]: 1, // Each datapoint 1 day apart
	[PairDataTimeWindowEnum.YEAR]: 15 // Each datapoint 15 days apart
}

export const ONE_DAY_UNIX = 86400 // 24h * 60m * 60s
export const ONE_HOUR_SECONDS = 3600
