import { Token } from '~/types/tokens'
import TokenLogo from '../Tokens/TokenLogo'

const FeaturedToken = ({
	tokenList,
	handleToken
}: {
	tokenList: Token[]
	handleToken: (token: Token) => void
}) => {
	return (
		<div className="mt-6 mb-2 gap-4 px-5 md:px-10">
			<h3 className="text-xs font-[600]">Common tokens</h3>
			<div className="bg-featured-tokens-overflow mt-2 flex justify-between gap-4 overflow-x-auto">
				{tokenList.map((token) => (
					<div
						className="btn-ghost btn flex cursor-pointer flex-row items-center gap-2 rounded-full bg-warning p-2 transition-all delay-[0] ease-in"
						onClick={() => {
							handleToken(token)
						}}
						key={token.symbol}
					>
						<TokenLogo token={token} />
						<span className="text-sm font-bold 2xl:text-base">
							{token.symbol}
						</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default FeaturedToken
